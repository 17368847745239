import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styles from "./styles/Home.module.css";

import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";

import MailchimpFormContainer from "../CommonComponents/MailchimpFormContainer";

export default function Home(props) {
  const [togglePopup, setTogglePopup] = useState(false);
  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>SwappCycle - Barter based Marketplace for Pre-loved Fashion</title>
        <meta
            name="description"
            content="SwappCycle is a Barter-based peer-to-peer Marketplace app for Pre-loved Fashion. Swap, save, and declutter with ease."
        />
        <meta
          name="keywords"
          content="Swap, Swapp, SwappCycle, Barter, Swapcycle, Barter, Swapcyle, Preloved, Preloved Fashion, Fashion, Swapper, Swappee, Declutter, Exchange, Clothing, Preloved Clothing, Used Clothing, Peer to Peer, Marketplace, Revolution, Consumption, Pre-loved, Thrift, Chain Swapp, Chain swap, Listing, Matches, Giveaway, Free Giveaway, Sustainable, Sustainable Fashion, Carbon footprint, Landfill, Trade, P2P
          "
        />
      </Helmet>
      <Section1 openSignupPopup={() => setTogglePopup(true)} />
      <Section2 />
      <Section3 />
      <Section4 openSignupPopup={() => setTogglePopup(true)} />
      {togglePopup ? (
        <MailchimpFormContainer close={() => setTogglePopup(false)} />
      ) : null}
    </div>
  );
}
