import React, { useEffect, useState } from "react";
import styles from "./styles/Section2.module.css";
import MaskGroup from "../../assets/images/mask-group.png";

import { getOperatingSystem } from "../../utils";

export default function Section2() {
  useEffect(() => {
    const OS = getOperatingSystem(window);
    if (OS == "MacOS") {
      const deviceWidth = window.innerWidth;
      if (deviceWidth <= 1024) {
        document.getElementById("section2").style.display = "none";
      }
    }
  });

  return (
    <div className={styles.wrapper} id="section2">
      <div className={styles.wrapperInner}></div>
    </div>
  );
}
