import React from "react";
import styles from "./styles/PolicyPages.module.css";

export default function FAQ(props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
      <h4>What is SwappCycle? </h4>
      <p className={styles.faqParagraphPadding}>SwappCycle is a barter-based peer-to-peer marketplace for pre-loved fashion!</p>

      <h4>Who can use SwappCycle?</h4>
      <p className={styles.faqParagraphPadding}>Just about EVERYONE! We are an inclusive platform for Men, Women and Kids of all ages and body types! No matter your style, we’re sure we’ve got something for you! </p>

      <h4>What is the benefit of using SwappCycle? </h4>
      <p className={styles.faqParagraphPadding}>Studies show that an average consumer uses LESS THAN HALF of their wardrobe on a regular basis! Billions of items therefore sit unused in closets, awaiting their chance to shine! SwappCycle gives you a chance to unlock the value trapped in unused treasures by exchanging them for other items that you could use! Therefore, it helps you save money while keeping your wardrobe fresh and fleek! It also makes it easy to declutter by donating your items to other people or causes you believe in. On the flipside, you could receive something you love for FREE too! </p>

      <h4>Can I sell my preloved items on SwappCycle? </h4>
      <p className={styles.faqParagraphPadding}>Unfortunately, no. SwappCycle is a Barter-based platform and Buying/Selling of items for money is not possible. </p>

      <h4>What can I list on SwappCycle? </h4>
      <p className={styles.faqParagraphPadding}>Usable and good quality items of just about anything as it relates to Fashion! Shirts, Pants, Kurtas, Bags, Shoes, Sunglasses, Jewellery, Wallets, Belts, Dresses, Suits, Lehengas, Salwards, Jackets, Sweaters, Coats, Jeans, Capris……….. (running out of space, sorry - but you get it!) </p>

      <h4>What can I NOT list on SwappCycle? </h4>
      <p className={styles.faqParagraphPadding}>Items that are damaged, worn-out or of unusable quality. We only have one thumb rule: Only list items of the same quality that you would be happy to receive in return! </p>

      <h4>Can I also list electronics, furniture, and other items on SwappCycle? </h4>
      <p className={styles.faqParagraphPadding}>Nope. SwappCycle caters exclusively to preloved fashion - Clothing and accessories.</p>

      <h4>Can I list an item for my partner or child? </h4>
      <p className={styles.faqParagraphPadding}>Absolutely! </p>

      <h4>How do I make a listing? </h4>
      <p className={styles.faqParagraphPadding}>Simply find an item that you no longer use in your wardrobe, click a few clear pictures, answer a few questions, and upload! </p>

      <h4>Do I need to list an item to start using SwappCycle? </h4>
      <p className={styles.faqParagraphPadding}>Not really. You could receive items for FREE from other users through the “Giveaway” feature. However, SwappCycle is a Community Platform that thrives as it grows! So we encourage you to start by making a listing! </p>

      <h4>How can I ensure that my listing is noticed? </h4>
      <p className={styles.faqParagraphPadding}>Avail one of many In-app services like “Boost-Listing” to draw unwavering attention to your listing and give it a moment of spotlight! </p>


      <h4>How can I find Matches/ Swapps for my item? </h4>
      <p className={styles.faqParagraphPadding}>Oh, wait till you hear - this is the best part. You don’t have to! All you have to do is swipe right to say “Yay” and swipe left to say “Nay” on items displayed as cards on the Swappdeck, and then sit back and let us do the rest! We will find the best Matches for you based on your interests and propose them to you! </p>

      <h4>How do I make sure I see products I like on the Swappdeck? </h4>
      <p className={styles.faqParagraphPadding}>You can indicate your product categories of preference to filter the items that you see! We also constantly try to learn your style and tastes through your interests, and will show you increasingly relevant listings. However, you never know what you might fall in love with, so we recommend you keep your options open!</p>

      <h4>I REALLY like someone’s item. Can I contact them directly? </h4>
      <p className={styles.faqParagraphPadding}>No! To keep SwappCycle spam-free, direct chat is only enabled when a match is created between two users. However, you can “favourite” their item to let them know you love it, and we will send them a ping to check out your listings!</p>

      <h4>How do you make Matches? </h4>
      <p className={styles.faqParagraphPadding}>Our expert-match-maker algorithm is based on multiple factors, that all influence the value of the items involved.</p>

      <h4>How do you determine the value of an item? </h4>
      <p className={styles.faqParagraphPadding}>We take several factors into consideration to determine the value of an item. Some examples of factors influencing our algorithm are the original price of the item, age of said item, condition of the item and longevity of the fabric/ base material.</p>

      <h4>Does a Match only have to be between similar items? </h4>
      <p className={styles.faqParagraphPadding}>Not at all! You can absolutely exchange your child’s birthday frock for a pair of sunglasses for yourself (ehm.. if such a Match can be made!) </p>

      <h4>Do items matched have to be of the same value? </h4>
      <p className={styles.faqParagraphPadding}>No, and that’s why we have Bundles! Two or more items of lesser value can be “bundled” and exchanged in return for one more expensive item! </p>

      <h4>Do I have to accept every Match proposed to me? </h4>
      <p className={styles.faqParagraphPadding}>Not at all! All parties involved have the complete right to reject a Match if they do not like the deal! No harm, No foul! </p>

      <h4>Do Swapps always need two participants? </h4>
      <p className={styles.faqParagraphPadding}>Nope! And here’s where things get really interesting! Swapps can involve upto FOUR users exchanging seamlessly among each other - we call this a “Chain-Swapp”. Our robust algorithm is dedicated to putting the right item in the hands of the right person! </p>

      <h4>I have a Match and I like the deal proposed, but I have some questions before I accept. What can I do? </h4>
      <p className={styles.faqParagraphPadding}>Simply hit up the owner of the item through the chat feature and get your questions clarified! Feel free to ask for more pictures and any additional details! </p>

      <h4>Is this really all free or are there any hidden costs? </h4>
      <p className={styles.faqParagraphPadding}>Yes, it really is all free! We only charge a nominal fee to provide shipping and other value added services!</p>

      <h4>How does Shipping work? </h4>
      <p className={styles.faqParagraphPadding}>We’re devoted to make Swapping as Easy, Effortless and Enjoyable as possible. The item you swapped will be picked up from your door-step and the corresponding item you received delivered to your home!</p>

      <h4>Can I also ship my item myself or meet my swap-partner in person to exchange my item? </h4>
      <p className={styles.faqParagraphPadding}>SwappCycle is dedicated to maintaining the privacy of its users and does not encourage the sharing of personal information such as addresses. Therefore these options are not possible.</p>

      <h4>How can I know if the item I am swapping is authentic and true to the listing? </h4>
      <p className={styles.faqParagraphPadding}>SwappCycle trusts its community to be honest and truthful! But if you would like to be extra sure, you can avail “quality-check” as a value added service - whereby the authenticity of your swapped item will be manually checked and verified! </p>

      <h4>I have hygiene concerns surrounding used clothing. What can I do? </h4>
      <p className={styles.faqParagraphPadding}>For an additional fee, we are happy to wash, dry, steam-press and bring you your swapped item as good as new! </p>

      <h4>I wish to declutter and have items to give away, but do not want anything in return. What can I do? </h4>
      <p className={styles.faqParagraphPadding}>Use the Giveaway feature to give your item away for free and make someone’s day! But wait, it gets better! You can also donate directly to featured NGOs and Non-profits on the platform to support a cause you believe in! </p>

      <h4>I would like to give away an item for free. Do I have to pay for shipping?</h4>
      <p className={styles.faqParagraphPadding}>No! The receiver of your item will bear the shipping expenses. </p>

      <h4>I received an swapped item, and am disappointed as it was completely misrepresented in the listing. What can I do? </h4>
      <p className={styles.faqParagraphPadding}>In valid cases of misrepresentation, where you receive a damaged or completely unrelated item, we will either reverse the Swapp or refund the value of your original item to your wallet. </p>

      <h4>I received a swapped item, and it is true to the listing - but I don’t love it. What can I do? </h4>
      <p className={styles.faqParagraphPadding}>No worries, just put it back on the platform! SwappCycle is an ever growing community wardrobe and someone else might be thrilled to have the item! </p>

      </div>
    </div>
  );
}
