import React from "react";
import styles from "./styles/Section3.module.css";

import SustainableSexy from "../../assets/images/sustainable-new-sexy.png";
import GiveAway from "../../assets/images/give-away.png";
export default function Section3() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <div className={styles.block1}>
          <div className={styles.h2}>
            <h2>Free Give-Away </h2>
          </div>
          <div className={styles.txtImage}>
            <div className={styles.txt}>
              <div>
                <h3>Just de-cluttering and getting rid of unused clothes?</h3>
                <p>
                  You can also swapp your items for a smile! With the
                  “Give-away” feature, give your item away for free or
                  donate to featured NGOs on the platform!
                  Kindness is always in style!
                </p>
              </div>
            </div>

            <div className={styles.img}>
              <img src={GiveAway} />
            </div>
          </div>
        </div>
        <div className={styles.block2}>
          <div className={styles.h2}>
            <h2>Sustainable is the new Sexy</h2>
          </div>
          <div className={styles.txtImage}>
            <div className={styles.txt}>
              <div>
                <p>
                SwappCycle empowers <b>YOU</b> to make a positive impact on our planet! 
                By swapping instead of shopping, you prevent generation of carbon footprint, 
                and grant a fresh lease of life to deserving items that would otherwise 
                end up in landfills!
                </p>
              </div>
            </div>

            <div className={styles.img}>
              <img src={SustainableSexy} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
