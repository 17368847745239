import SignupPopup from "./SignupPopup";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import { MAILCHIMP_ID, MAILCHIMP_U } from "../../config.js";
const MailchimpFormContainer = (props) => {
  const postUrl = `https://swappcycle.us21.list-manage.com/subscribe/post?u=${MAILCHIMP_U}&id=${MAILCHIMP_ID}`;
  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <SignupPopup
            close={props.close}
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default MailchimpFormContainer;
