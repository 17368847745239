import React from "react";
import ReactGA from 'react-ga';
import CookiePolicy from "../../components/PolicyPages/CookiePolicy";
import WebApp from "../../components/WebApp";
import ReactPixel from 'react-facebook-pixel';
import { InitFacebookPixel } from "../../utils/FacebookPixel";

export default function CookiePolicPage(props) {
  InitFacebookPixel();
  ReactPixel.pageView();
  
  ReactGA.pageview('Cookie Policy');
  return (
    <WebApp>
      <CookiePolicy />
    </WebApp>
  );
}
