import React, { useEffect, useState } from "react";
import styles from "./styles/MobileMenuBar.module.css";

import { Link } from "react-router-dom";
import { InitFacebookPixel } from "../../utils/FacebookPixel";
import Logo from "../../assets/logo.png";

export default function MobileMenuBar(props) {
  const [hamburgerMenu, setHamburgerMenu] = useState(false);
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <div className={styles.leftPart}>
          <div className={styles.logoContainer}>
            <Link to={`/home`}>
              <img src={Logo} />
            </Link>
          </div>
        </div>
        <div className={styles.rightPart}>
          <div
            className={styles.toggleIconCont}
            onClick={() => setHamburgerMenu(!hamburgerMenu)}
          >
            <svg
              width="37"
              height="32"
              viewBox="0 0 37 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.3125 4.57143H34.6875C36.075 4.57143 37 3.65714 37 2.28571C37 0.914286 36.075 0 34.6875 0H2.3125C0.925 0 0 0.914286 0 2.28571C0 3.65714 0.925 4.57143 2.3125 4.57143ZM2.3125 18.2857H34.6875C36.075 18.2857 37 17.3714 37 16C37 14.6286 36.075 13.7143 34.6875 13.7143H2.3125C0.925 13.7143 0 14.6286 0 16C0 17.3714 0.925 18.2857 2.3125 18.2857ZM2.3125 32H34.6875C36.075 32 37 31.0857 37 29.7143C37 28.3429 36.075 27.4286 34.6875 27.4286H2.3125C0.925 27.4286 0 28.3429 0 29.7143C0 31.0857 0.925 32 2.3125 32Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>

      {hamburgerMenu ? (
        <Hamburger close={() => setHamburgerMenu(false)} />
      ) : null}
    </div>
  );
}

class Hamburger extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.componentState = {
      homeSelected: !window.location.href.includes("faq"),
      faqSelected: window.location.href.includes("faq"),
      blogSelected: false,
      internshipSelected: false,
    };
    this.recalculateClasses();
  }

  recalculateClasses() {
    this.homeClassnames = this.componentState.homeSelected ? styles.current: "";
    this.faqClassnames = this.componentState.faqSelected ? styles.current: "";
    this.blogClassnames = this.componentState.blogSelected ? styles.current: "";
    this.internshipClassnames = this.componentState.internshipSelected ? styles.current: "";
  }

  resetState() {
    this.componentState = {
      homeSelected: false,
      faqSelected: false,
      blogSelected: false,
      internshipSelected: false,
    };
  }

  selectFAQ = (e) => {
    this.resetState();
    this.componentState.faqSelected = true;
    this.recalculateClasses();
    this.forceUpdate();
    this.props.close();
  }


  selectHome = () => {
    this.resetState();
    this.componentState.homeSelected = true;
    this.recalculateClasses();
    this.forceUpdate();
    this.props.close();
  }

  componentDidMount() {
    InitFacebookPixel();
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div className={styles.hamburger}>
      <div className={styles.crossIconCont}>
        <div onClick={this.props.close} style={{ padding: "10px" }}>
          <svg
            width="34"
            height="33"
            viewBox="0 0 34 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.7499 19.4593L3.90222 32.3697C3.4851 32.7899 2.99427 33 2.42975 33C1.86622 33 1.3754 32.7899 0.957271 32.3697C0.539142 31.9496 0.330078 31.4564 0.330078 30.8901C0.330078 30.3228 0.539142 29.8296 0.957271 29.4104L13.805 16.5L0.957271 3.58959C0.539142 3.17042 0.330078 2.6772 0.330078 2.10992C0.330078 1.54365 0.539142 1.05043 0.957271 0.630255C1.3754 0.210085 1.86622 0 2.42975 0C2.99427 0 3.4851 0.210085 3.90222 0.630255L16.7499 13.5407L29.5976 0.630255C30.0147 0.210085 30.5055 0 31.0701 0C31.6336 0 32.1244 0.210085 32.5425 0.630255C32.9607 1.05043 33.1697 1.54365 33.1697 2.10992C33.1697 2.6772 32.9607 3.17042 32.5425 3.58959L19.6949 16.5L32.5425 29.4104C32.9607 29.8296 33.1697 30.3228 33.1697 30.8901C33.1697 31.4564 32.9607 31.9496 32.5425 32.3697C32.1244 32.7899 31.6336 33 31.0701 33C30.5055 33 30.0147 32.7899 29.5976 32.3697L16.7499 19.4593Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div>
        <div className={styles.nav}>
          <ul>
            <li>
              <Link to={`/`} className={this.homeClassnames} onClick={this.selectHome}>
                About us
              </Link>
            </li>
            {/* <li>
              <Link to={`/about-us`}>About Us</Link>
            </li> */}
            <li className={this.blogClassnames}>
                <a href="https://blog.swappcycle.com/">Blog</a>
              </li>
              <li className={this.internshipClassnames}>
                <a href="https://internships.swappcycle.com/">Internships</a>
              </li>
            <li>
              <Link  className={this.faqClassnames} onClick={this.selectFAQ} to={`/faq`}>FAQ</Link>
            </li>
            <li>
              <a href="#contactUs" onClick={this.props.close}>Contact Us</a>
            </li>
            {/* TODO: this should be deleted after razorpay onboarding. */}
            {/* <li>
                <a href="http://sale.swappcycle.com/">Products</a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
    );
  }
}
