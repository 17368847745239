import React from "react";
import styles from "./styles/Section4.module.css";

import SignupBtn from "../CommonComponents/SignupBtn";
export default function Section4(props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <div className={styles.h2}>
          <h2>Join the revolution in consumption now</h2>
        </div>
        <div style={{ textAlign: "center" }}>
          <div>
            <SignupBtn />
          </div>
        </div>
      </div>
    </div>
  );
}
