import ReactPixel from 'react-facebook-pixel';

function InitFacebookPixel() {
  const advancedMatching = {};
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init('315076727626552', advancedMatching, options);
}

export { InitFacebookPixel };
