import React from "react";
import TermsAndConditions from "../../components/PolicyPages/TermsAndConditions";
import WebApp from "../../components/WebApp";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { InitFacebookPixel } from "../../utils/FacebookPixel";

export default function TermsAndConditionsPage(props) {
  InitFacebookPixel();
  ReactPixel.pageView();
  
  ReactGA.pageview('Terms and Conditions');
  return (
    <WebApp>
      <TermsAndConditions />
    </WebApp>
  );
}
