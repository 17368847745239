import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import PrivacyPolicyPage from "../pages/PrivacyPolicy";
import FAQPage from "../pages/FAQPage";
import TermsAndConditionsPage from "../pages/TermsAndConditions";
import CookiePolicPage from "../pages/CookiePolicy";

const TheRouter = (props) => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route
          exact
          path="/terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />
        <Route exact path="/faq" element={<FAQPage />} />
        <Route exact path="/cookie-policy" element={<CookiePolicPage />} />
      </Routes>
    </Router>
  );
};

export default TheRouter;
