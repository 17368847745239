import React from "react";
import Home from "../../components/Home";
import WebApp from "../../components/WebApp";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { InitFacebookPixel } from "../../utils/FacebookPixel";

export default function HomePage(props) {
  InitFacebookPixel();
  ReactPixel.pageView();

  ReactGA.pageview('Home Page');
  return (
    <WebApp>
      <Home />
    </WebApp>
  );
}
