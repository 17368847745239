import React from "react";
import styles from "./styles/CommonComponents.module.css";

export default function SignupBtn(props) {
  const handleIOSRedirect = () => {
    // Redirect to the iOS App Store
    window.location.href = "https://apps.apple.com/us/app/swappcycle/id1627325345";
  };

  const handleAndroidRedirect = () => {
    // Redirect to the Android Play Store
    window.location.href = "https://play.google.com/store/apps/details?id=com.swappcycle.recycle";
  };

  return (
    <div className={styles.buttonContainer}>
      <button className={`${styles.signupBtn} ${styles.iosBtn}`} onClick={handleIOSRedirect}>
        Get it from App Store
      </button>
      <button className={`${styles.signupBtn} ${styles.androidBtn}`} onClick={handleAndroidRedirect}>
        Get it on Google Play
      </button>
    </div>
  );
}
