import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./styles/SignupPopup.module.css";
import OpenLetter from "../../assets/images/open-letter.png";

export default function SignupPopup(props) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState(false);
  useEffect(() => {
    setStatus(props.status);
    setMessage(props.message);

    if (props.status == "success") {
      setEmail("");
      setFirstName("");
      setLastName("");
    }
  }, [props]);

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      firstName &&
      lastName &&
      email.indexOf("@") > -1 &&
      props.onValidated({
        MERGE0: email,
        MERGE1: firstName,
        MERGE2: lastName,
      });
  };

  return (
    <div className={styles.signupPopupContainer}>
      <div className={styles.bgOverlay}></div>
      <div className={styles.signupPopup}>
        <div className={styles.crossIconCont} onClick={props.close}>
          <svg
            width="34"
            height="33"
            viewBox="0 0 34 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.7499 19.4593L3.90222 32.3697C3.4851 32.7899 2.99427 33 2.42975 33C1.86622 33 1.3754 32.7899 0.957271 32.3697C0.539142 31.9496 0.330078 31.4564 0.330078 30.8901C0.330078 30.3228 0.539142 29.8296 0.957271 29.4104L13.805 16.5L0.957271 3.58959C0.539142 3.17042 0.330078 2.6772 0.330078 2.10992C0.330078 1.54365 0.539142 1.05043 0.957271 0.630255C1.3754 0.210085 1.86622 0 2.42975 0C2.99427 0 3.4851 0.210085 3.90222 0.630255L16.7499 13.5407L29.5976 0.630255C30.0147 0.210085 30.5055 0 31.0701 0C31.6336 0 32.1244 0.210085 32.5425 0.630255C32.9607 1.05043 33.1697 1.54365 33.1697 2.10992C33.1697 2.6772 32.9607 3.17042 32.5425 3.58959L19.6949 16.5L32.5425 29.4104C32.9607 29.8296 33.1697 30.3228 33.1697 30.8901C33.1697 31.4564 32.9607 31.9496 32.5425 32.3697C32.1244 32.7899 31.6336 33 31.0701 33C30.5055 33 30.0147 32.7899 29.5976 32.3697L16.7499 19.4593Z"
              fill="white"
            />
          </svg>
        </div>

        {status == "success" ? (
          <div className={styles.signupResponse}>
            <div className={styles.imageCont}>
              <img src={OpenLetter} />
            </div>
            {/* <div className={styles.description}>
              <p>{message}</p>
            </div> */}
            <div className={styles.description}>
              <p>
                Almost there! We just need to confirm your email address. Please click the link in the email we sent or check your spam if you don't find it!
              </p>
            </div>
            <div className={styles.submitBtn} onClick={props.close}>
              <button>okay got it</button>
            </div>
          </div>
        ) : (
          <form
            className={styles.signupPopupInner}
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className={styles.heading}>
              <h2>Sign up for Beta</h2>
            </div>

            {status == "error" && props.message ? (
              <div className={styles.errorResponse}>
                <p>{message}</p>
              </div>
            ) : null}

            <div className={styles.inputFields}>
              <div className={styles.input}>
                <input
                  type="text"
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
              </div>

              <div className={styles.input}>
                <input
                  type="text"
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
              </div>

              <div className={styles.emailinput}>
                <input
                  type="email"
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
            </div>
            <div className={styles.submitBtn}>
              <button
                type="submit"
                label="submit"
                formValues={[email, firstName, lastName]}
              >
                <span>submit</span>

                {status == "sending" ? (
                  <span>
                    <ClipLoader
                      color="#C0D884"
                      loading={status == "sending" ? true : false}
                      size={30}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      cssOverride={{
                        marginLeft: "8px",
                      }}
                    />
                  </span>
                ) : null}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
