import React from "react";
import styles from "./styles/WebApp.module.css";

import { useMediaQuery } from "react-responsive";

import MenuBar from "../CommonComponents/MenuBar";
import MobileMenuBar from "../CommonComponents/MobileMenuBar";
import Footer from "../CommonComponents/Footer";

export default function WebApp(props) {
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        {isTablet ? <MobileMenuBar /> : <MenuBar />}

        {props.children}
        <Footer />
      </div>
    </div>
  );
}
