import React from "react";
import FAQ from "../../components/PolicyPages/FAQ";
import WebApp from "../../components/WebApp";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { InitFacebookPixel } from "../../utils/FacebookPixel";

export default function FAQPage(props) {
  InitFacebookPixel();
  ReactPixel.pageView();

  ReactGA.pageview('FAQ');
  return (
    <WebApp>
      <FAQ />
    </WebApp>
  );
}
