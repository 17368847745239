import React from "react";
import styles from "./styles/Footer.module.css";

import { Link } from "react-router-dom";

import FacebookIcon from "../../assets/images/facebook-icon.png";
import LinkedIcon from "../../assets/images/linkedin-icon.png";
import InstagramIcon from "../../assets/images/instagram-icon.png";
import TwitterIcon from "../../assets/images/twitter-icon.png";
import Logo from "../../assets/logo.png";

export default function Footer(props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <div className={styles.footerBlock}>
          <div className={styles.block1}>
            <div className={styles.logoCont}>
              <img src={Logo} />
            </div>
          </div>

          <div className={styles.block23}>
            <div className={styles.block2}>
              <div className={styles.h3}>
                <h3>Legal</h3>
              </div>
              <div className={styles.legalBlock}>
                <p>
                  <a href="/privacy-policy">Privacy Policy</a>
                </p>
                <p>
                  <a href="/terms-and-conditions">Terms and Conditions</a>
                </p>
                <p>
                  <a href="/cookie-policy">Cookie Policy</a>
                </p>
              </div>
            </div>
            <div className={styles.block3}>
              <div className={styles.h3} id="contactUs">
                <h3>Contact us</h3>
              </div>
              <div className={styles.contactUsBlock}>
                <div>
                  <a href="https://www.facebook.com/SwappCycle" target="_blank">
                    <img src={FacebookIcon} />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.linkedin.com/company/swappcycle/"
                    target="_blank"
                  >
                    <img src={LinkedIcon} />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/swappcycle/"
                    target="_blank"
                  >
                    <img src={InstagramIcon} />
                  </a>
                </div>
                <div>
                  <a href="https://twitter.com/SwappCycle" target="_blank">
                    <img src={TwitterIcon} />
                  </a>
                </div>
              </div>
              <div className={styles.phoneAndAddressBlock}>
              Phone: +91-9080432275
              </div>
              <div className={styles.phoneAndAddressBlock}>
              Address: Survey Number 552 1A/ 1A, Door number 194, Oak wood cottage road, Off Thomas Church Road, Ooty, Nilgiris, Tamil Nadu - 643001.
              </div>
            </div>
          </div>
          <div className={styles.block4}>
            <div className={styles.h3}>
              <h3>Connect with us</h3>
            </div>
            <div className={styles.emailBlock}>
              <p>
                <a href="mailto:mail@swappcycle.com">mail@swappcycle.com</a>
              </p>
              <p>
                <a href="mailto:hr@swappcycle.com">hr@swappcycle.com</a>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.rightsReserved}>
          <p>© 2022 SwappCycle Private Limited | All rights reserved</p>
        </div>
      </div>
    </div>
  );
}
