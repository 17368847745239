import React from "react";
import styles from "./styles/MenuBar.module.css";

import { Link } from "react-router-dom";
import { InitFacebookPixel } from "../../utils/FacebookPixel";

import Logo from "../../assets/logo.png";
export default class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.componentState = {
      homeSelected: !window.location.href.includes("faq"),
      faqSelected: window.location.href.includes("faq"),
      blogSelected: false,
      internshipSelected: false,
    };
    this.recalculateClasses();
  }

  recalculateClasses() {
    this.homeClassnames = this.componentState.homeSelected ? styles.current: "";
    this.faqClassnames = this.componentState.faqSelected ? styles.current: "";
    this.blogClassnames = this.componentState.blogSelected ? styles.current: "";
    this.internshipClassnames = this.componentState.internshipSelected ? styles.current: "";
  }

  resetState() {
    this.componentState = {
      homeSelected: false,
      faqSelected: false,
      blogSelected: false,
      internshipSelected: false,
    };
  }

  selectFAQ = (e) => {
    this.resetState();
    this.componentState.faqSelected = true;
    this.recalculateClasses();
    this.forceUpdate();
  }


  selectHome = () => {
    this.resetState();
    this.componentState.homeSelected = true;
    this.recalculateClasses();
    this.forceUpdate();
  }

  componentDidMount() {
    InitFacebookPixel();
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div className={styles.wrapper} id="navbar">
      <div className={styles.wrapperInner}>
        <div className={styles.leftPart}>
          <div className={styles.logoContainer}>
            <Link to={`/`}>
              <img src={Logo} />
            </Link>
          </div>
        </div>
        <div className={styles.rightPart}>
          <div className={styles.nav}>
            <ul>
              <li>
                <Link to={`/`} className={this.homeClassnames} onClick={this.selectHome}>
                 About us
                </Link>
              </li>
              {/* <li>
                <Link to={`/about-us`}>About Us</Link>
              </li> */}
              <li className={this.blogClassnames}>
                <a href="https://blog.swappcycle.com/">Blog</a>
              </li>
              <li className={this.internshipClassnames}>
                <a href="https://internships.swappcycle.com/">Internships</a>
              </li>
              <li>
                <Link className={this.faqClassnames} onClick={this.selectFAQ} to={`/faq`}>FAQ</Link>
              </li>
              <li>
                <a href="#contactUs">Contact Us</a>
              </li>
              {/* <li>
                <a href="http://sale.swappcycle.com/">Products</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
