import React, { useEffect, useState, useRef } from "react";
import {
  AnimatePresence,
  motion,
  useScroll,
  MotionConfig,
  useSpring,
  useTransform,
  MotionValue,
} from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";

import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

import styles from "./styles/Section1.module.css";
import SignupBtn from "../CommonComponents/SignupBtn";

import BringingBarterBack from "../../assets/images/bringing-barter-back.png";

import MobileGroup1 from "../../assets/images/mobile-group-1.png";
import MobileGroup2 from "../../assets/images/mobile-group-2.png";
import MobileGroup3 from "../../assets/images/mobile-group-3.png";
import MobileGroup4 from "../../assets/images/mobile-group-4.png";
import MobileGroup5 from "../../assets/images/mobile-group-5.png";

import WhereEverybodyWins from "../../assets/images/where-everybody-wins.png";
import WhereEverybodyWins2 from "../../assets/images/where-everybody-wins2.png";
import WhyUseSwapCycle from "../../assets/images/why-user-swap-cycle.png";
import WhyUseSwapCycle2 from "../../assets/images/why-user-swap-cycle2.png";
import MoreTheMerrier from "../../assets/images/more-the-merrier.png";

import SwapCycleUse from "../../assets/images/swap-cycle-use.png";
import SwapCycleUse2 from "../../assets/images/swap-cycle-use2.png";
import SwapCycleUse3 from "../../assets/images/swap-cycle-use3.png";
import SwapCycleUse4 from "../../assets/images/swap-cycle-use4.png";
import SwapCycleUse5 from "../../assets/images/swap-cycle-use5.png";

const slashMotionFunc = (scale, x, y) => {
  return {
    rest: { opacity: 1, ease: "easeOut", duration: 2, type: "tween" },
    exit: { scale: scale, y: y, x: x },
    hover: {
      opacity: 1,
      scale: scale,
      y: y,
      x: x,
      transition: {
        delay: 0.0,
        duration: 1.5,
        type: "tween",
        ease: "easeOut",
      },
    },
  };
};

const swapCycleSteps = [
  {
    no: "01.",
    heading: "Make a new listing",
    description:
      "Simply find an item you would like to exchange, take a picture and upload! ",
    image: SwapCycleUse2,
  },
  {
    no: "02.",
    heading: "Start Swiping",
    description:
      "Browse through an ever-growing catalogue of items on offer and swipe right or left to say “Yay” or “Nay”! ",
    image: SwapCycleUse3,
  },
  {
    no: "03.",
    heading: "Get Match’d",
    description:
      "Sit back, relax and leave it to us to find you the best matches based on your interests! ",
    image: SwapCycleUse4,
  },
  {
    no: "04.",
    heading: "Swapp. Set. Go!",
    description:
      "Approve the matches you like to have your item picked up, and the item of your dreams delivered to your doorstep!",
    image: SwapCycleUse5,
  },
];

export default function Section1(props) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-width: 641px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 640px)",
  });

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const scrollRef = useRef(null);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <div className={styles.block1}>
          <div className={styles.txtImage}>
            <div className={styles.txt}>
              <div className={styles.h2}>
                <h2>Bringing Barter Back</h2>
              </div>
              <div className={styles.txtBlocks}>
                <p className={styles.marketeP}>
                  Peer to peer marketplace for pre-loved fashion.
                </p>
                <p className={styles.revolutionP}>
                  Join the revolution in consumption now
                </p>
              </div>
              <div className={styles.signupBtnCont}>
                <div>
                  <SignupBtn />
                </div>
              </div>
            </div>

            <div className={styles.img}>
              <img src={BringingBarterBack} />
            </div>
          </div>
        </div>
        <motion.div className={styles.block2}>
          <div className={styles.h2}>
            <h2>Why Shop when you can Swapp? </h2>
          </div>
          <div className={styles.txtImage}>
            <motion.div
              div
              viewport={{ root: scrollRef }}
              initial="rest"
              whileInView="hover"
              animate="rest"
            >
              {isDesktop ? (
                <div className={styles.masksGroup}>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage1}`}
                    variants={slashMotionFunc(2.2, -370, -115)}
                    initial={{ x: -51 }}
                  >
                    <img src={MobileGroup1} />
                  </motion.div>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage2}`}
                    variants={slashMotionFunc(2.7, -180, -160)}
                    initial={{ x: -29 }}
                  >
                    <img src={MobileGroup2} />
                  </motion.div>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage3}`}
                    variants={slashMotionFunc(3, 0, -195)}
                  >
                    <img src={MobileGroup3} />
                  </motion.div>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage4}`}
                    variants={slashMotionFunc(2.7, 180, -160)}
                    initial={{ x: 29 }}
                  >
                    <img src={MobileGroup4} />
                  </motion.div>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage5}`}
                    variants={slashMotionFunc(2.2, 370, -115)}
                    initial={{ x: 51 }}
                  >
                    <img src={MobileGroup5} />
                  </motion.div>
                </div>
              ) : isTablet ? (
                <div className={styles.masksGroup}>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage1}`}
                    variants={slashMotionFunc(2, -240, -102)}
                    initial={{ x: -51 }}
                  >
                    <img src={MobileGroup1} />
                  </motion.div>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage2}`}
                    variants={slashMotionFunc(2.2, -120, -120)}
                    initial={{ x: -29 }}
                  >
                    <img src={MobileGroup2} />
                  </motion.div>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage3}`}
                    variants={slashMotionFunc(2.5, 0, -150)}
                  >
                    <img src={MobileGroup3} />
                  </motion.div>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage4}`}
                    variants={slashMotionFunc(2.2, 120, -120)}
                    initial={{ x: 29 }}
                  >
                    <img src={MobileGroup4} />
                  </motion.div>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage5}`}
                    variants={slashMotionFunc(2, 240, -102)}
                    initial={{ x: 51 }}
                  >
                    <img src={MobileGroup5} />
                  </motion.div>
                </div>
              ) : (
                <div className={styles.masksGroup}>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage1}`}
                    variants={slashMotionFunc(1.1, -120, -10)}
                    initial={{ x: -51 }}
                  >
                    <img src={MobileGroup1} />
                  </motion.div>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage2}`}
                    variants={slashMotionFunc(1.3, -60, -30)}
                    initial={{ x: -29 }}
                  >
                    <img src={MobileGroup2} />
                  </motion.div>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage3}`}
                    variants={slashMotionFunc(1.5, 0, -50)}
                  >
                    <img src={MobileGroup3} />
                  </motion.div>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage4}`}
                    variants={slashMotionFunc(1.3, 60, -30)}
                    initial={{ x: 29 }}
                  >
                    <img src={MobileGroup4} />
                  </motion.div>
                  <motion.div
                    className={`${styles.maskImage} ${styles.maskImage5}`}
                    variants={slashMotionFunc(1.1, 120, -10)}
                    initial={{ x: 51 }}
                  >
                    <img src={MobileGroup5} />
                  </motion.div>
                </div>
              )}
            </motion.div>
          </div>
        </motion.div>
        <div className={styles.block3}>
          <div className={styles.h2}>
            <h2>
              SwappCycle:
              <br /> Where everybody wins.
            </h2>
          </div>
          <div className={styles.txtImage}>
            <div className={styles.txt}>
              <div>
                <p>
                  <b>
                  <i>Swapper</i> (You):
                  </b>{" "}
                  Get value for your forgotten treasures lying unused <b>AND</b>{" "}
                  declutter!
                </p>
                <p>
                  <b>
                    <i>Swappee</i> (also You):
                  </b>{" "}
                  Keep your wardrobe fresh and fleek <b>FOR FREE!</b>
                </p>
              </div>

              <div>
                <p className={styles.revolutionP}>
                  <b>The planet and your purse will thank you!</b>
                </p>
              </div>
            </div>

            <div className={styles.img}>
              <img src={WhereEverybodyWins} className={styles.img1} />
              <img src={WhereEverybodyWins2} className={styles.img2} />
            </div>
          </div>
        </div>
        <div className={styles.block4}>
          <div className={styles.h2}>
            <h2> Why use SwappCycle? </h2>
          </div>
          <div
            className={`${styles.txt} ${styles.txt2}`}
            style={{ maxWidth: "none" }}
          >
            <p>Where do we Start? More importantly, how do we Stop?</p>
          </div>
          <div className={styles.txtImage}>
            <div className={styles.txt}>
              <div>
                <ul>
                  <li>
                    <p>
                      <b>Easy and Effortless:</b> An ever-growing community
                      wardrobe delivered to your doorstep!
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Thrifty and nifty way to stay trendy:</b> Why flick it
                      if you can flip it?
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Fun and Fresh:</b> A new and exciting way to consume
                      fashion!
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div className={styles.img}>
              <img src={WhyUseSwapCycle} className={styles.img1} />
              <img src={WhyUseSwapCycle2} className={styles.img2} />
            </div>
          </div>
        </div>
        <div className={styles.block5}>
          <div className={styles.h2}>
            <h2>More the Merrier!</h2>
          </div>
          <div className={styles.txtImage}>
            <div className={styles.txt}>
              <div>
                <p>
                  That’s why our expert-matchmaker algorithm can create <b>Chains
                  of Swappers!</b> Swapps can involve upto four users exchanging
                  seamlessly among each other. The right item in the hands of
                  the right person, it really is that simple!
                </p>
              </div>
            </div>

            <div className={styles.img}>
              <img src={MoreTheMerrier} />
            </div>
          </div>
        </div>
        <div className={`${styles.block6} `}>
          <div className={styles.h2}>
            <h2>How can you use SwappCycle?</h2>
          </div>

          <div className={styles.swapCycleContainer}>
            {swapCycleSteps.map((step, index) => (
              <ListingItem1 {...step} key={index} index={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const ListingItem1 = ({ no, heading, description, image, index }) => {
  return (
    <div className={styles.swapCycle}>
      <div className={styles.txtImage}>
        <AnimationOnScroll
          className={styles.txt}
          animateIn="animate__fadeInUp"
          animateOut="animate__fadeOutUp"
        >
          <div className={styles.listing}>
            <div className={styles.numberHeading}>
              <div className={styles.listingNumber}>
                <h3>{no}</h3>
              </div>
              <div>
                <h3 className={styles.listingHeading}>{heading}</h3>
                <p className={styles.listingDescription}>{description}</p>

                {index == 4 ? (
                  <p style={{ marginTop: "10px" }}>
                    <b>
                      <i>It really is that simple! </i>
                    </b>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </AnimationOnScroll>

        <div className={styles.img}>
          <AnimationOnScroll
            className={styles.img1}
            animateIn="animate__slideInRight"
            animateOut="animate__slideOutLeft"
          >
            <img src={image} />
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
};
