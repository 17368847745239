import React from "react";
import PrivacyPolicy from "../../components/PolicyPages/PrivacyPolicy";
import WebApp from "../../components/WebApp";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { InitFacebookPixel } from "../../utils/FacebookPixel";

export default function PrivacyPolicyPage(props) {
  InitFacebookPixel();
  ReactPixel.pageView();

  ReactGA.pageview('Privacy Policy');
  return (
    <WebApp>
      <PrivacyPolicy />
    </WebApp>
  );
}
